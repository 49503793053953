var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.userInfo.templateExample == '1')?[_c('div',{staticClass:"birthday-report"},[(!_vm.isPhone)?_c('edp-header',{staticClass:"header-bg",attrs:{"headerLeftWord":_vm.$t('birthdayReport.headerLeftWord')}}):_vm._e(),_c('div',{class:_vm.isPhone
            ? `birthday-report-content-phone`
            : `birthday-report-content`},[_c('div',{class:_vm.isPhone
              ? `birthday-report-fireworks-phone`
              : _vm.isPC
              ? `birthday-report-fireworks-isPc`
              : _vm.isLargePc
              ? `birthday-report-fireworks-isLargePc`
              : `birthday-report-fireworks`},[_c('span',{class:_vm.isPhone
                ? `birthday-report-fireworks-text-phone`
                : _vm.isPC
                ? `birthday-report-fireworks-text-isPc`
                : _vm.isLargePc
                ? `birthday-report-fireworks-text-isLargePc`
                : `birthday-report-fireworks-text`},[_vm._v(_vm._s(_vm.userInfo?.birthday))])]),_c('div',{class:_vm.isPhone ? `birthday-report-happy-phone` : `birthday-report-happy`},[_vm._v(" Happy Birthday ")]),_c('div',{class:_vm.isPhone
              ? `birthday-report-photo-phone`
              : _vm.isPC
              ? `birthday-report-photo-isPc`
              : _vm.isLargePc
              ? `birthday-report-photo-isLargePc`
              : `birthday-report-photo`,style:({
            backgroundImage: `url(${_vm.userInfo?.photo ?? _vm.nullImg})`,
            backgroundSize: 'cover',
          })}),_c('div',{class:_vm.isPhone ? `birthday-report-name-phone` : `birthday-report-name`},[_vm._v(" DEAR "+_vm._s(_vm.lang === "cn" ? _vm.userInfo?.cname : _vm.userInfo?.ename)+" ")]),_c('div',{class:_vm.isPhone
              ? `birthday-report-flowers-phone`
              : _vm.isPC
              ? `birthday-report-flowers-isPc`
              : _vm.isLargePc
              ? `birthday-report-flowers-isLargePc`
              : `birthday-report-flowers`}),_c('div',{class:[
            _vm.isPhone
              ? `birthday-report-text-phone`
              : _vm.isPC
              ? `birthday-report-text-isPc`
              : _vm.isLargePc
              ? `birthday-report-text-isLargePc`
              : `birthday-report-text`,
            _vm.isPC ? `birthday-report-text-PC` : ``,
          ],style:(_vm.isPhone
              ? _vm.lang === 'en'
                ? `line-height: 23px;`
                : ``
              : _vm.isLargePc
              ? _vm.lang === 'en'
                ? `line-height: 24px;`
                : ``
              : ``)},[_c('p',{domProps:{"innerHTML":_vm._s(
              _vm.$t(
                _vm.isPhone
                  ? `birthdayReport.ContentPhone`
                  : `birthdayReport.Content`
              )
            )}})]),_c('div',{class:[
            _vm.isPhone
              ? `birthday-report-reminder-phone`
              : _vm.isPC
              ? `birthday-report-reminder-isPc`
              : _vm.isLargePc
              ? `birthday-report-reminder-isLargePc`
              : `birthday-report-reminder`,
            _vm.lang === 'en' && !_vm.isPhone
              ? _vm.isLargePc
                ? `birthday-report-reminder-en-isLargePc`
                : `birthday-report-reminder-en`
              : ``,
          ]},[_c('p',{class:[
              _vm.isPhone
                ? `birthday-report-reminder-text-phone`
                : _vm.isPC
                ? `birthday-report-reminder-text-isPc`
                : _vm.isLargePc
                ? `birthday-report-reminder-text-isLargePc`
                : `birthday-report-reminder-text`,
              _vm.isPhone
                ? _vm.lang === 'en'
                  ? `birthday-report-reminder-text-en-phone`
                  : `birthday-report-reminder-text`
                : _vm.lang === 'en'
                ? _vm.isPC
                  ? `birthday-report-reminder-text-en-isPc`
                  : _vm.isLargePc
                  ? `birthday-report-reminder-text-en-isLargePc`
                  : `birthday-report-reminder-text-en`
                : `birthday-report-reminder-text`,
            ],domProps:{"innerHTML":_vm._s(_vm.$t('birthdayReport.Reminder'))}}),_c('p',{class:[
              _vm.isPhone
                ? `birthday-report-points-phone`
                : _vm.isPC
                ? `birthday-report-points-isPc`
                : _vm.isLargePc
                ? `birthday-report-points-isLargePc`
                : `birthday-report-points`,
              _vm.isPhone
                ? _vm.lang === 'en'
                  ? `birthday-report-points-en-phone`
                  : `birthday-report-points`
                : _vm.lang == 'en'
                ? `birthday-report-points-en`
                : `birthday-report-points`,
            ],domProps:{"innerHTML":_vm._s(_vm.$t('birthdayReport.Points'))}})])])],1)]:_vm._e(),(_vm.userInfo.templateExample == '2')?[_c('div',{staticClass:"birthday-report temp-two"},[(!_vm.isPhone)?_c('edp-header',{staticClass:"header-bg",attrs:{"headerLeftWord":_vm.$t('birthdayReport.headerLeftWord')}}):_vm._e(),_c('div',{class:_vm.isPhone
            ? `birthday-report-content-phone`
            : `birthday-report-content`},[_c('div',{class:_vm.isPhone
              ? `birthday-report-photo-phone`
              : _vm.isPC
              ? `birthday-report-photo-isPc`
              : _vm.isLargePc
              ? `birthday-report-photo-isLargePc`
              : `birthday-report-photo`,style:({
            backgroundImage: `url(${_vm.userInfo?.photo ?? _vm.nullImg})`,
            backgroundSize: 'cover',
          })}),_c('div',{class:_vm.isPhone ? `birthday-report-name-phone` : `birthday-report-name`},[_vm._v(" TO "+_vm._s(_vm.lang === "cn" ? _vm.userInfo?.cname : _vm.userInfo?.ename)+" ")]),_c('div',{class:[
            _vm.isPhone
              ? `birthday-report-text-phone`
              : _vm.isPC
              ? `birthday-report-text-isPc`
              : _vm.isLargePc
              ? `birthday-report-text-isLargePc`
              : `birthday-report-text`,
            _vm.isPC ? `birthday-report-text-PC` : ``,
          ],style:(_vm.isPhone
              ? _vm.lang === 'en'
                ? `line-height: 23px;`
                : ``
              : _vm.isLargePc
              ? _vm.lang === 'en'
                ? `line-height: 24px;`
                : ``
              : ``)},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t(`birthdayReport.ContentTwo`))}})]),_c('div',{class:[
            _vm.isPhone
              ? `birthday-report-reminder-phone`
              : _vm.isPC
              ? `birthday-report-reminder-isPc`
              : _vm.isLargePc
              ? `birthday-report-reminder-isLargePc`
              : `birthday-report-reminder`,
            _vm.lang === 'en' && !_vm.isPhone
              ? _vm.isLargePc
                ? `birthday-report-reminder-en-isLargePc`
                : `birthday-report-reminder-en`
              : ``,
          ]},[_c('p',{class:[
              _vm.isPhone
                ? `birthday-report-reminder-text-phone`
                : _vm.isPC
                ? `birthday-report-reminder-text-isPc`
                : _vm.isLargePc
                ? `birthday-report-reminder-text-isLargePc`
                : `birthday-report-reminder-text`,
              _vm.isPhone
                ? _vm.lang === 'en'
                  ? `birthday-report-reminder-text-en-phone`
                  : `birthday-report-reminder-text`
                : _vm.lang === 'en'
                ? _vm.isPC
                  ? `birthday-report-reminder-text-en-isPc`
                  : _vm.isLargePc
                  ? `birthday-report-reminder-text-en-isLargePc`
                  : `birthday-report-reminder-text-en`
                : `birthday-report-reminder-text`,
            ],domProps:{"innerHTML":_vm._s(_vm.$t('birthdayReport.Reminder'))}}),_c('p',{class:[
              _vm.isPhone
                ? `birthday-report-points-phone`
                : _vm.isPC
                ? `birthday-report-points-isPc`
                : _vm.isLargePc
                ? `birthday-report-points-isLargePc`
                : `birthday-report-points`,
              _vm.isPhone
                ? _vm.lang === 'en'
                  ? `birthday-report-points-en-phone`
                  : `birthday-report-points`
                : _vm.lang == 'en'
                ? `birthday-report-points-en`
                : `birthday-report-points`,
            ],domProps:{"innerHTML":_vm._s(_vm.$t('birthdayReport.Points'))}})])])],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }