<template>
  <div>
    <template v-if="userInfo.templateExample == '1'">
      <div class="birthday-report">
        <edp-header
          v-if="!isPhone"
          class="header-bg"
          :headerLeftWord="$t('birthdayReport.headerLeftWord')"
        ></edp-header>
        <div
          :class="
            isPhone
              ? `birthday-report-content-phone`
              : `birthday-report-content`
          "
        >
          <div
            :class="
              isPhone
                ? `birthday-report-fireworks-phone`
                : isPC
                ? `birthday-report-fireworks-isPc`
                : isLargePc
                ? `birthday-report-fireworks-isLargePc`
                : `birthday-report-fireworks`
            "
          >
            <span
              :class="
                isPhone
                  ? `birthday-report-fireworks-text-phone`
                  : isPC
                  ? `birthday-report-fireworks-text-isPc`
                  : isLargePc
                  ? `birthday-report-fireworks-text-isLargePc`
                  : `birthday-report-fireworks-text`
              "
              >{{ userInfo?.birthday }}</span
            >
          </div>
          <div
            :class="
              isPhone ? `birthday-report-happy-phone` : `birthday-report-happy`
            "
          >
            Happy Birthday
          </div>
          <div
            :style="{
              backgroundImage: `url(${userInfo?.photo ?? nullImg})`,
              backgroundSize: 'cover',
            }"
            :class="
              isPhone
                ? `birthday-report-photo-phone`
                : isPC
                ? `birthday-report-photo-isPc`
                : isLargePc
                ? `birthday-report-photo-isLargePc`
                : `birthday-report-photo`
            "
          ></div>
          <div
            :class="
              isPhone ? `birthday-report-name-phone` : `birthday-report-name`
            "
          >
            DEAR {{ lang === "cn" ? userInfo?.cname : userInfo?.ename }}
          </div>
          <div
            :class="
              isPhone
                ? `birthday-report-flowers-phone`
                : isPC
                ? `birthday-report-flowers-isPc`
                : isLargePc
                ? `birthday-report-flowers-isLargePc`
                : `birthday-report-flowers`
            "
          ></div>
          <div
            :class="[
              isPhone
                ? `birthday-report-text-phone`
                : isPC
                ? `birthday-report-text-isPc`
                : isLargePc
                ? `birthday-report-text-isLargePc`
                : `birthday-report-text`,
              isPC ? `birthday-report-text-PC` : ``,
            ]"
            :style="
              isPhone
                ? lang === 'en'
                  ? `line-height: 23px;`
                  : ``
                : isLargePc
                ? lang === 'en'
                  ? `line-height: 24px;`
                  : ``
                : ``
            "
          >
            <p
              v-html="
                $t(
                  isPhone
                    ? `birthdayReport.ContentPhone`
                    : `birthdayReport.Content`
                )
              "
            ></p>
          </div>
          <div
            :class="[
              isPhone
                ? `birthday-report-reminder-phone`
                : isPC
                ? `birthday-report-reminder-isPc`
                : isLargePc
                ? `birthday-report-reminder-isLargePc`
                : `birthday-report-reminder`,
              lang === 'en' && !isPhone
                ? isLargePc
                  ? `birthday-report-reminder-en-isLargePc`
                  : `birthday-report-reminder-en`
                : ``,
            ]"
          >
            <p
              :class="[
                isPhone
                  ? `birthday-report-reminder-text-phone`
                  : isPC
                  ? `birthday-report-reminder-text-isPc`
                  : isLargePc
                  ? `birthday-report-reminder-text-isLargePc`
                  : `birthday-report-reminder-text`,
                isPhone
                  ? lang === 'en'
                    ? `birthday-report-reminder-text-en-phone`
                    : `birthday-report-reminder-text`
                  : lang === 'en'
                  ? isPC
                    ? `birthday-report-reminder-text-en-isPc`
                    : isLargePc
                    ? `birthday-report-reminder-text-en-isLargePc`
                    : `birthday-report-reminder-text-en`
                  : `birthday-report-reminder-text`,
              ]"
              v-html="$t('birthdayReport.Reminder')"
            ></p>
            <p
              :class="[
                isPhone
                  ? `birthday-report-points-phone`
                  : isPC
                  ? `birthday-report-points-isPc`
                  : isLargePc
                  ? `birthday-report-points-isLargePc`
                  : `birthday-report-points`,
                isPhone
                  ? lang === 'en'
                    ? `birthday-report-points-en-phone`
                    : `birthday-report-points`
                  : lang == 'en'
                  ? `birthday-report-points-en`
                  : `birthday-report-points`,
              ]"
              v-html="$t('birthdayReport.Points')"
            ></p>
          </div>
        </div>
      </div>
    </template>
    <template v-if="userInfo.templateExample == '2'">
      <div class="birthday-report temp-two">
        <edp-header
          v-if="!isPhone"
          class="header-bg"
          :headerLeftWord="$t('birthdayReport.headerLeftWord')"
        ></edp-header>
        <div
          :class="
            isPhone
              ? `birthday-report-content-phone`
              : `birthday-report-content`
          "
        >
          <div
            :style="{
              backgroundImage: `url(${userInfo?.photo ?? nullImg})`,
              backgroundSize: 'cover',
            }"
            :class="
              isPhone
                ? `birthday-report-photo-phone`
                : isPC
                ? `birthday-report-photo-isPc`
                : isLargePc
                ? `birthday-report-photo-isLargePc`
                : `birthday-report-photo`
            "
          ></div>
          <div
            :class="
              isPhone ? `birthday-report-name-phone` : `birthday-report-name`
            "
          >
            TO {{ lang === "cn" ? userInfo?.cname : userInfo?.ename }}
          </div>
          <div
            :class="[
              isPhone
                ? `birthday-report-text-phone`
                : isPC
                ? `birthday-report-text-isPc`
                : isLargePc
                ? `birthday-report-text-isLargePc`
                : `birthday-report-text`,
              isPC ? `birthday-report-text-PC` : ``,
            ]"
            :style="
              isPhone
                ? lang === 'en'
                  ? `line-height: 23px;`
                  : ``
                : isLargePc
                ? lang === 'en'
                  ? `line-height: 24px;`
                  : ``
                : ``
            "
          >
            <p v-html="$t(`birthdayReport.ContentTwo`)"></p>
          </div>
          <div
            :class="[
              isPhone
                ? `birthday-report-reminder-phone`
                : isPC
                ? `birthday-report-reminder-isPc`
                : isLargePc
                ? `birthday-report-reminder-isLargePc`
                : `birthday-report-reminder`,
              lang === 'en' && !isPhone
                ? isLargePc
                  ? `birthday-report-reminder-en-isLargePc`
                  : `birthday-report-reminder-en`
                : ``,
            ]"
          >
            <p
              :class="[
                isPhone
                  ? `birthday-report-reminder-text-phone`
                  : isPC
                  ? `birthday-report-reminder-text-isPc`
                  : isLargePc
                  ? `birthday-report-reminder-text-isLargePc`
                  : `birthday-report-reminder-text`,
                isPhone
                  ? lang === 'en'
                    ? `birthday-report-reminder-text-en-phone`
                    : `birthday-report-reminder-text`
                  : lang === 'en'
                  ? isPC
                    ? `birthday-report-reminder-text-en-isPc`
                    : isLargePc
                    ? `birthday-report-reminder-text-en-isLargePc`
                    : `birthday-report-reminder-text-en`
                  : `birthday-report-reminder-text`,
              ]"
              v-html="$t('birthdayReport.Reminder')"
            ></p>
            <p
              :class="[
                isPhone
                  ? `birthday-report-points-phone`
                  : isPC
                  ? `birthday-report-points-isPc`
                  : isLargePc
                  ? `birthday-report-points-isLargePc`
                  : `birthday-report-points`,
                isPhone
                  ? lang === 'en'
                    ? `birthday-report-points-en-phone`
                    : `birthday-report-points`
                  : lang == 'en'
                  ? `birthday-report-points-en`
                  : `birthday-report-points`,
              ]"
              v-html="$t('birthdayReport.Points')"
            ></p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { computed } from "vue";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  name: "birthdayReport",
  data() {
    return {
      userInfo: {
        templateExample: null,
      },
      onlyId: null,
      currentYear: null,
      isPhone: false,
      isPC: false,
      isPad: false,
      windowWidth: document.documentElement.clientWidth, //获取屏幕宽度
      isLargePc: false,
      nullImg: require("@/src/assets/common/defaultImg.png"),
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.login.lang,
    }),
  },
  beforeDestroy() {
    if (!this.isPad) {
      window.removeEventListener("resize", this.windowZoomChange);
    }
  },
  mounted() {
    this.determineDevice();
    this.onlyId = this.$route.query?.onlyId;
    this.currentYear = this.$route.query?.currentYear;
    this.windowWidth = document.documentElement.clientWidth;
    if (this.windowWidth < 800) {
      this.isPhone = true;
    } else {
      this.isPhone = false;
    }
    if (this.windowWidth > 1200 && this.windowWidth < 1600 && !this.isPad) {
      this.isPC = true;
    } else {
      this.isPC = false;
    }
    if (this.windowWidth > 1600 && !this.isPad) {
      this.isLargePc = true;
    } else {
      this.isLargePc = false;
    }
    if (this.isPhone) {
      this.getReportBirthdayCurrentEmp();
    } else {
      this.getReportBirthdayDetail();
    }
  },
  methods: {
    ...mapActions({
      reportBirthdayCurrentEmp: "reportCover/reportBirthdayCurrentEmp",
      reportBirthdayDetail: "reportCover/reportBirthdayDetail",
    }),
    async getReportBirthdayCurrentEmp() {
      let params = {
        onlyId: this.onlyId,
        currentYear: this.currentYear,
      };
      let res = await this.reportBirthdayCurrentEmp(params);
    },
    async getReportBirthdayDetail() {
      let res = await this.reportBirthdayDetail(this.onlyId).then((res) => {
        this.userInfo = res.data?.data;
      });
    },
    determineDevice() {
      var agent = navigator.userAgent.toLowerCase();
      var ipad = agent.indexOf("ipad");
      var pad = agent.indexOf("pad");
      if (pad != -1 || ipad != -1) {
        this.isPad = true;
      } else {
        this.isPad = false;
        var macApp = window.navigator.userAgent.match(/Macintosh/i) != null;
        if (macApp) {
          var canvas = document.createElement("canvas");
          if (canvas != null) {
            var context =
              canvas.getContext("webgl") ||
              canvas.getContext("experimental-webgl");
            if (context) {
              var info = context.getExtension("WEBGL_debug_renderer_info");
              if (info) {
                var renderer = context.getParameter(
                  info.UNMASKED_RENDERER_WEBGL
                );
                if (renderer.indexOf("Apple") != -1) {
                  this.isPad = true;
                }
              }
            }
          }
        }
        if (!this.isPad) {
          window.addEventListener("resize", this.windowZoomChange);
        }
      }
    },
    windowZoomChange() {
      this.determineDevice();
      this.windowWidth = document.documentElement.clientWidth;
      if (this.windowWidth > 1200 && this.windowWidth < 1600 && !this.isPad) {
        this.isPC = true;
      } else {
        this.isPC = false;
      }
      if (this.windowWidth > 1600 && !this.isPad) {
        this.isLargePc = true;
      } else {
        this.isLargePc = false;
      }
      window.location.reload();
    },
  },
};
</script>
<style lang="scss">
@import "@/src/styles/variable.scss";

.birthday-report {
  width: 100%;
  height: 100vh;
  .edp-header {
    background-color: white;
  }
  .birthday-report-content {
    background: url("~@/src/assets/birthdayReport/bd-pad.png");
    background-position: center 0;
    background-size: 100%;
    background-repeat: no-repeat;
    -webkit-background-size: 100%; /* 兼容Webkit内核浏览器如Chrome和Safari */
    -o-background-size: 100%; /* 兼容Opera */
    height: 93vh;
    overflow: hidden;
    width: 100%;
    opacity: 0.75;
    display: inline-block;
    justify-content: center;
    align-items: center;
  }
  .birthday-report-content-phone {
    background: url("~@/src/assets/birthdayReport/bd-app.png");
    background-position: center 0;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-size: cover; /* 兼容Webkit内核浏览器如Chrome和Safari */
    -o-background-size: cover; /* 兼容Opera */
    height: 100vh;
  }
  .birthday-report-fireworks {
    margin: toPad(30) auto auto auto;
    width: toPad(289);
    height: toPad(80);
    z-index: 2;
    background: url("~@/src/assets/birthdayReport/fireworks-pad.png") no-repeat
      center;
    background-size: cover;
  }
  .birthday-report-fireworks-isPc {
    margin: toPad(30) auto auto auto;
    width: toPad(180);
    height: toPad(40);
    z-index: 2;
    background: url("~@/src/assets/birthdayReport/fireworks-pad.png") no-repeat
      center;
    background-size: cover;
  }
  .birthday-report-fireworks-isLargePc {
    margin: toPad(30) auto auto auto;
    width: toPad(200);
    height: toPad(40);
    z-index: 2;
    background: url("~@/src/assets/birthdayReport/fireworks-pad.png") no-repeat
      center;
    background-size: cover;
  }
  .birthday-report-fireworks-phone {
    position: absolute;
    width: 195.13px;
    height: 64.17px;
    margin: 88px auto auto calc((100vw - 195.13px) / 2);
    background: url("~@/src/assets/birthdayReport/fireworks-app.png") no-repeat
      center;
    background-size: cover;
  }
  .birthday-report-fireworks-text {
    position: absolute;
    width: toPad(149);
    height: toPad(89);
    margin: toPad(16) auto auto toPad(70);
    font-family: JLREmeric;
    font-weight: 600;
    font-size: toPad(73.91);
    line-height: toPad(89);
    text-align: Center;
    letter-spacing: toPad(-4.43);
    color: #fff;
    filter: drop-shadow(toPad(0), toPad(2.96) rgba(62, 136, 83, 0.13));
    border-radius: toPad(6);
  }
  .birthday-report-fireworks-text-isPc {
    position: absolute;
    width: toPad(75);
    height: toPad(45);
    margin: toPad(-10) auto auto toPad(40);
    font-family: JLREmeric;
    font-weight: 600;
    font-size: toPad(48);
    line-height: toPad(89);
    text-align: Center;
    letter-spacing: toPad(0);
    color: #fff;
    filter: drop-shadow(toPad(0), toPad(2.96) rgba(62, 136, 83, 0.13));
    border-radius: toPad(6);
  }
  .birthday-report-fireworks-text-isLargePc {
    position: absolute;
    width: toPad(75);
    height: toPad(45);
    margin: toPad(-10) auto auto toPad(50);
    font-family: JLREmeric;
    font-weight: 600;
    font-size: toPad(48);
    line-height: toPad(89);
    text-align: Center;
    letter-spacing: toPad(0);
    color: #fff;
    filter: drop-shadow(toPad(0), toPad(2.96) rgba(62, 136, 83, 0.13));
    border-radius: toPad(6);
  }
  .birthday-report-fireworks-text-phone {
    position: absolute;
    width: 101px;
    height: 60px;
    margin: 17px auto auto 43.13px;
    font-family: JLREmeric;
    font-weight: 600;
    font-size: 50px;
    line-height: 60px;
    text-align: Center;
    letter-spacing: -3px;
    color: #fff;
    filter: drop-shadow(0px, 2px rgba(62, 136, 83, 0.13));
    border-radius: 4px;
  }
  .birthday-report-happy {
    position: relative;
    width: toPad(143);
    height: toPad(25);
    margin: toPad(20) auto auto auto;
    font-family: JLREmeric;
    font-weight: 400;
    font-size: toPad(20);
    line-height: toPad(25);
    text-align: Center;
    color: #fff;
    filter: drop-shadow(0, 2.96px rgba(62, 136, 83, 0.13));
    border-radius: toPad(6);
  }
  .birthday-report-happy-phone {
    position: absolute;
    width: 111px;
    height: 19px;
    margin: 165px calc((100vw - 111px) / 2) auto calc((100vw - 111px) / 2);
    font-family: JLREmeric;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.2px;
    text-align: Center;
    color: #fff;
    filter: drop-shadow(0, 2.96px rgba(62, 136, 83, 0.13));
    border-radius: 6px;
  }
  .birthday-report-photo {
    width: toPad(172);
    height: toPad(172);
    margin: toPad(38) auto auto auto;
    border-radius: toPad(95);
    border: toPad(5) solid #ffffff;
  }
  .birthday-report-photo-isPc {
    width: toPad(100);
    height: toPad(100);
    margin: toPad(16) auto auto auto;
    border-radius: toPad(95);
    border: toPad(5) solid #ffffff;
  }
  .birthday-report-photo-isLargePc {
    width: toPad(150);
    height: toPad(150);
    margin: toPad(16) auto auto auto;
    border-radius: toPad(95);
    border: toPad(5) solid #ffffff;
  }
  .birthday-report-photo-phone {
    position: absolute;
    width: 100px;
    height: 100px;
    margin: 189px auto auto calc((100vw - 100px) / 2);
    border-radius: 73px;
    border: 2px solid #ffffff;
  }
  .birthday-report-name {
    width: toPad(285);
    height: toPad(22);
    margin: toPad(13) auto auto auto;
    font-family: JLREmeric;
    font-weight: 400;
    font-size: toPad(20);
    line-height: toPad(25);
    text-align: Center;
    color: #000;
    text-transform: uppercase;
  }
  .birthday-report-name-phone {
    position: absolute;
    width: 285px;
    height: 19px;
    margin: 298px auto auto calc((100vw - 285px) / 2);
    font-family: JLREmeric;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: Center;
    color: #000;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .birthday-report-flowers {
    width: toPad(530);
    height: toPad(180);
    margin: toPad(16) auto auto auto;
    background: url("~@/src/assets/birthdayReport/flowers-pad.png") no-repeat
      center;
    background-size: contain;
  }
  .birthday-report-flowers-isPc {
    width: toPad(530);
    height: toPad(100);
    margin: toPad(16) auto auto auto;
    background: url("~@/src/assets/birthdayReport/flowers-pad.png") no-repeat
      center;
    background-size: contain;
  }
  .birthday-report-flowers-isLargePc {
    width: toPad(530);
    height: toPad(150);
    margin: toPad(16) auto auto auto;
    background: url("~@/src/assets/birthdayReport/flowers-pad.png") no-repeat
      center;
    background-size: contain;
  }
  .birthday-report-flowers-phone {
    position: absolute;
    width: calc(100vw - 4px);
    height: 156px;
    margin: 325px auto auto 2px;
    background: url("~@/src/assets/birthdayReport/flowers-app.png") no-repeat
      center;
    background-size: cover;
  }
  .birthday-report-text {
    width: toPad(1055);
    height: toPad(67);
    margin: toPad(37) auto auto auto;
    font-family: JLREmeric;
    font-weight: 400;
    font-size: toPad(22);
    line-height: toPad(33);
    text-align: Center;
    color: #1e392f;
  }
  .birthday-report-text-isPc {
    width: toPad(1055);
    height: toPad(30);
    margin: toPad(20) auto auto auto;
    font-family: JLREmeric;
    font-weight: 400;
    font-size: toPad(12);
    line-height: toPad(16);
    text-align: Center;
    color: #1e392f;
  }
  .birthday-report-text-isLargePc {
    width: toPad(1055);
    height: toPad(30);
    margin: toPad(20) auto auto auto;
    font-family: JLREmeric;
    font-weight: 400;
    font-size: toPad(16);
    line-height: toPad(28);
    text-align: Center;
    color: #1e392f;
  }
  .birthday-report-text-PC {
    margin: toPad(20) auto auto auto;
    line-height: toPad(16);
  }
  .birthday-report-text-phone {
    position: absolute;
    width: calc(100vw - 56px);
    height: 92px;
    margin: 500px 28px auto 28px;
    font-family: JLREmeric;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    text-align: Center;
    color: #1e392f;
  }
  .birthday-report-reminder {
    width: toPad(409);
    height: toPad(94);
    margin: toPad(16) auto toPad(80) auto;
    background: linear-gradient(
      to right,
      rgba(97, 191, 180, 0.08),
      rgba(255, 211, 108, 0.08)
    );
    border-radius: toPad(3);
  }
  .birthday-report-reminder-isPc {
    width: toPad(260);
    height: toPad(72);
    margin: toPad(20) auto toPad(80) auto;
    background: linear-gradient(
      to right,
      rgba(97, 191, 180, 0.08),
      rgba(255, 211, 108, 0.08)
    );
    border-radius: toPad(3);
  }
  .birthday-report-reminder-isLargePc {
    width: toPad(300);
    height: toPad(94);
    margin: toPad(40) auto toPad(80) auto;
    background: linear-gradient(
      to right,
      rgba(97, 191, 180, 0.08),
      rgba(255, 211, 108, 0.08)
    );
    border-radius: toPad(3);
  }
  .birthday-report-reminder-phone {
    position: absolute;
    width: calc(100vw - 96px);
    height: 65px;
    margin: 607px 48px auto 48px;
    overflow: hidden;
    background: linear-gradient(
      to right,
      rgba(97, 191, 180, 0.08),
      rgba(255, 211, 108, 0.08)
    );
    border-radius: 3px;
  }
  .birthday-report-reminder-en {
    margin: toPad(31) auto toPad(80) auto;
  }
  .birthday-report-reminder-en-isLargePc {
    margin: toPad(41) auto toPad(80) auto;
  }
  .birthday-report-reminder-text {
    padding-top: toPad(16);
    margin: toPad(16) auto auto auto;
    width: 100%;
    height: toPad(27);
    font-family: JLREmeric;
    font-weight: 400;
    font-size: toPad(18);
    line-height: toPad(27);
    text-align: Center;
    color: rgba(30, 57, 47, 0.6);
  }
  .birthday-report-reminder-text-isPc {
    padding-top: toPad(16);
    margin: toPad(16) auto auto auto;
    width: 100%;
    height: toPad(14);
    font-family: JLREmeric;
    font-weight: 400;
    font-size: toPad(12);
    line-height: toPad(16);
    text-align: Center;
    color: rgba(30, 57, 47, 0.6);
  }
  .birthday-report-reminder-text-isLargePc {
    padding-top: toPad(16);
    margin: toPad(16) auto auto auto;
    width: 100%;
    height: toPad(14);
    font-family: JLREmeric;
    font-weight: 400;
    font-size: toPad(16);
    line-height: toPad(24);
    text-align: Center;
    color: rgba(30, 57, 47, 0.6);
  }
  .birthday-report-reminder-text-phone {
    margin: 11px auto auto auto;
    width: 100%;
    height: 18px;
    font-family: JLREmeric;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: Center;
    color: rgba(30, 57, 47, 0.6);
  }
  .birthday-report-reminder-text-en {
    line-height: toPad(22);
    padding-top: toPad(8);
  }
  .birthday-report-reminder-text-en-isPc {
    line-height: toPad(16);
    padding-top: toPad(8);
  }
  .birthday-report-reminder-text-en-isLargePc {
    line-height: toPad(20);
    padding-top: toPad(12);
  }
  .birthday-report-reminder-text-en-phone {
    line-height: 13px;
    margin-top: 7px;
  }
  .birthday-report-points {
    padding-top: toPad(10);
    margin: toPad(10) auto auto auto;
    width: 100%;
    height: toPad(27);
    font-family: JLREmeric;
    font-weight: 600;
    font-size: toPad(20);
    line-height: toPad(28);
    text-align: Center;
    color: #3e886d;
  }
  .birthday-report-points-isPc {
    padding-top: toPad(10);
    margin: toPad(10) auto auto auto;
    width: 100%;
    height: toPad(27);
    font-family: JLREmeric;
    font-weight: 600;
    font-size: toPad(12);
    line-height: toPad(16);
    text-align: Center;
    color: #3e886d;
  }
  .birthday-report-points-isLargePc {
    padding-top: toPad(10);
    margin: toPad(20) auto auto auto;
    width: 100%;
    height: toPad(27);
    font-family: JLREmeric;
    font-weight: 600;
    font-size: toPad(16);
    line-height: toPad(20);
    text-align: Center;
    color: #3e886d;
  }
  .birthday-report-points-phone {
    margin: 0px auto auto auto;
    width: 100%;
    height: 28px;
    font-family: JLREmeric;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: Center;
    color: #3e886d;
  }
  .birthday-report-points-en {
    padding-top: toPad(20);
    text-transform: uppercase;
  }
  .birthday-report-points-en-phone {
    margin-top: 8px;
    text-transform: uppercase;
  }
  &.temp-two {
    .birthday-report-content {
      background: url("~@/src/assets/birthdayReport/birthday-pc.png");
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      -webkit-background-size: cover; /* 兼容Webkit内核浏览器如Chrome和Safari */
      -o-background-size: cover; /* 兼容Opera */
    }
    .birthday-report-content-phone {
      background: url("~@/src/assets/birthdayReport/birthday-mob.png");
      background-position: center 0;
      background-size: cover;
      background-repeat: no-repeat;
      -webkit-background-size: cover; /* 兼容Webkit内核浏览器如Chrome和Safari */
      -o-background-size: cover; /* 兼容Opera */
    }
    .birthday-report-photo {
      width: toPad(110);
      height: toPad(110);
      margin: toPad(100) toPad(120) toPad(30) toPad(200);
    }
    .birthday-report-photo-isPc {
      width: toPad(110);
      height: toPad(110);
      margin: toPad(100) toPad(120) toPad(30) toPad(200);
    }
    .birthday-report-photo-isLargePc {
      width: toPad(110);
      height: toPad(110);
      margin: toPad(100) toPad(120) toPad(30) toPad(200);
    }
    .birthday-report-photo-phone {
      margin: 189px auto auto calc((100vw - 100px) / 2);
    }
    .birthday-report-name {
      margin: 0 0 toPad(26) toPad(200);
      text-align: left;
    }
    .birthday-report-name-phone {
      margin: 298px auto auto calc((100vw - 285px) / 2);
    }
    .birthday-report-text {
      height: auto;
      margin: 0 0 toPad(50) toPad(200);
      text-align: left;
    }
    .birthday-report-text-isPc {
      height: auto;
      line-height: toPad(30);
      margin: 0 0 toPad(50) toPad(200);
      font-size: toPad(22);
      text-align: left;
    }
    .birthday-report-text-isLargePc {
      height: auto;
      line-height: toPad(30);
      margin: 0 0 toPad(50) toPad(200);
      font-size: toPad(22);
      text-align: left;
    }
    .birthday-report-text-PC {
      margin: 0 0 toPad(50) toPad(200);
      font-size: toPad(22);
      text-align: left;
    }
    .birthday-report-text-phone {
      position: absolute;
      width: calc(100vw - 56px);
      height: 92px;
      margin: 500px 28px auto 28px;
      font-family: JLREmeric;
      font-weight: 400;
      font-size: 15px;
      line-height: 23px;
      text-align: left;
      color: #1e392f;
    }
    .birthday-report-reminder {
      margin: toPad(50) auto toPad(20) toPad(200);
      background: none;
      border-radius: 0;
    }
    .birthday-report-reminder-isPc {
      width: toPad(320);
      margin: toPad(50) auto toPad(20) toPad(200);
      background: none;
      border-radius: 0;
    }
    .birthday-report-reminder-isLargePc {
      margin: toPad(50) auto toPad(20) toPad(200);
      background: none;
      border-radius: 0;
    }
    .birthday-report-reminder-phone {
      position: absolute;
      width: calc(100vw - 96px);
      height: 65px;
      margin: 607px 48px auto 48px;
      overflow: hidden;
      background: linear-gradient(
        to right,
        rgba(97, 191, 180, 0.08),
        rgba(255, 211, 108, 0.08)
      );
      border-radius: 3px;
    }
    .birthday-report-reminder-en {
      margin: toPad(50) auto toPad(40) toPad(200);
    }
    .birthday-report-reminder-en-isLargePc {
      margin: toPad(50) auto toPad(40) toPad(200);
    }
    .birthday-report-reminder-text {
      padding-top: toPad(16);
      margin: toPad(50) auto toPad(40) 0;
      text-align: left;
    }
    .birthday-report-reminder-text-isPc {
      margin: toPad(50) auto toPad(40) 0;
      font-size: toPad(18);
      text-align: left;
    }
    .birthday-report-reminder-text-isLargePc {
      margin: toPad(50) auto toPad(40) 0;
      font-size: toPad(18);
      text-align: left;
    }
    .birthday-report-reminder-text-phone {
      margin: 11px auto auto auto;
      width: 100%;
      height: 18px;
      font-family: JLREmeric;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-align: Center;
      color: rgba(30, 57, 47, 0.6);
    }
    .birthday-report-points {
      margin: toPad(10) auto auto auto;
      width: 100%;
      height: toPad(27);
      font-family: JLREmeric;
      font-weight: 600;
      font-size: toPad(20);
      line-height: toPad(28);
      text-align: left;
      color: #3e886d;
    }
    .birthday-report-points-isPc {
      padding-top: toPad(10);
      margin: toPad(10) auto auto auto;
      text-align: left;
    }
    .birthday-report-points-isLargePc {
      margin: toPad(20) auto auto auto;
      text-align: left;
    }
    .birthday-report-points-phone {
      margin: 0px auto auto auto;
      text-align: left;
    }
  }
}
</style>
